@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?70537448');
  src: url('../font/fontello.eot?70537448#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?70537448') format('woff2'),
       url('../font/fontello.woff?70537448') format('woff'),
       url('../font/fontello.ttf?70537448') format('truetype'),
       url('../font/fontello.svg?70537448#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?70537448#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-refresh:before { content: '\e800'; } /* '' */
.icon-refund:before { content: '\e801'; } /* '' */
.icon-fee-certificate:before { content: '\e802'; } /* '' */
.icon-pay-invoice:before { content: '\e803'; } /* '' */
.icon-invoice-view:before { content: '\e804'; } /* '' */
.icon-transport:before { content: '\e805'; } /* '' */
.icon-change-program:before { content: '\e806'; } /* '' */
.icon-past-request:before { content: '\e807'; } /* '' */
.icon-child-withdraw:before { content: '\e808'; } /* '' */
.icon-it-support:before { content: '\e809'; } /* '' */
.icon-rest-child:before { content: '\e80a'; } /* '' */
.icon-invoice:before { content: '\e80b'; } /* '' */
.icon-holiday-calender:before { content: '\e80c'; } /* '' */
.icon-meal:before { content: '\e80d'; } /* '' */
.icon-outdoor-activity:before { content: '\e80e'; } /* '' */
.icon-refine:before { content: '\e80f'; } /* '' */
.icon-remove:before { content: '\e810'; } /* '' */
.icon-sliders:before { content: '\e811'; } /* '' */
.icon-calender:before { content: '\e812'; } /* '' */
.icon-chat:before { content: '\e813'; } /* '' */
.icon-newspaper:before { content: '\e814'; } /* '' */
.icon-photo-gallery-2:before { content: '\e815'; } /* '' */
.icon-signout:before { content: '\e816'; } /* '' */
.icon-growth:before { content: '\e817'; } /* '' */
.icon-footprint:before { content: '\e818'; } /* '' */
.icon-user:before { content: '\e819'; } /* '' */
.icon-camera:before { content: '\e81a'; } /* '' */
.icon-support:before { content: '\e81b'; } /* '' */
.icon-home:before { content: '\e81c'; } /* '' */
.icon-notification:before { content: '\e81d'; } /* '' */
.icon-back:before { content: '\e81e'; } /* '' */
.icon-photo-gallery:before { content: '\e81f'; } /* '' */
.icon-referral:before { content: '\e820'; } /* '' */
.icon-slider-activity-icon:before { content: '\e821'; } /* '' */
.icon-video:before { content: '\e822'; } /* '' */
.icon-video-group-icon:before { content: '\e823'; } /* '' */
.icon-video-group-icon-2:before { content: '\e824'; } /* '' */
.icon-video-play-icon:before { content: '\e825'; } /* '' */
.icon-log-out:before { content: '\e826'; } /* '' */
.icon-log-off:before { content: '\e827'; } /* '' */
.icon-edit-view-01:before { content: '\e828'; } /* '' */
.icon-edit:before { content: '\e829'; } /* '' */
.icon-email:before { content: '\e82a'; } /* '' */
.icon-phone:before { content: '\e82b'; } /* '' */
.icon-father:before { content: '\e82c'; } /* '' */
.icon-class:before { content: '\e82d'; } /* '' */
.icon-programs:before { content: '\e82e'; } /* '' */
.icon-birthday:before { content: '\e82f'; } /* '' */
.icon-cycling:before { content: '\e830'; } /* '' */
.icon-baby-food:before { content: '\e831'; } /* '' */
.icon-rhymes:before { content: '\e832'; } /* '' */
.icon-painting:before { content: '\e833'; } /* '' */
.icon-send:before { content: '\e834'; } /* '' */
.icon-thinking:before { content: '\e835'; } /* '' */
.icon-happy:before { content: '\e836'; } /* '' */
.icon-sad:before { content: '\e837'; } /* '' */
.icon-cctv-1:before { content: '\e838'; } /* '' */
.icon-cctv-2:before { content: '\e839'; } /* '' */
.icon-cctv-3:before { content: '\e83a'; } /* '' */
.icon-cctv-4:before { content: '\e83b'; } /* '' */
.icon-cctv-5:before { content: '\e83c'; } /* '' */
.icon-cctv-6:before { content: '\e83d'; } /* '' */
.icon-cctv-7:before { content: '\e83e'; } /* '' */
.icon-cctv-9:before { content: '\e83f'; } /* '' */
.icon-cctv-8:before { content: '\e840'; } /* '' */
.icon-admission:before { content: '\e841'; } /* '' */
.icon-arrow_up:before { content: '\e842'; } /* '' */
.icon-arrow-down:before { content: '\e843'; } /* '' */
.icon-timeline:before { content: '\e844'; } /* '' */
.icon-withdraw:before { content: '\e845'; } /* '' */